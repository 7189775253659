import { endOfMonth, format as dateFormat, parseISO } from 'date-fns'
import {
    ContractType,
    DateType,
    MonthRange,
    Option,
    TimeEntryPayPeriod,
    TempPasswordRules,
    EditingTask,
    TaskIssueInformationFrequency
} from '@/types'
import _ from 'lodash'
import SettingService from '@/services/setting-service'
import { Loading, QSpinner, ComponentConstructor, Screen } from 'quasar'
import type { Component } from 'vue'
import { useToast } from 'vue-toastification'

const toast = useToast()

export default class Common {
    public static showLoading(delay = 500, size = Screen.lt.sm ? 75 : 150, color = 'primary', spinner: ComponentConstructor<Component> = QSpinner, message = '') {
        Loading.show({
            delay: delay,
            spinnerSize: size,
            spinnerColor: color,
            spinner: spinner,
            message: message
        })
    }

    public static hideLoading() {
        Loading.hide()
    }

    public static getDateFromRange(value: Date[]|MonthRange[], dateType: DateType, isMonthRange = false): string|null {
        let dateValue: Date|MonthRange
        let month: number|null = null
        let day: number|null = null
        let year: number| null = null

        if (value.length) {
            if (dateType === DateType.StartDate) {
                day = 1
                dateValue = value[0]
            } else {
                dateValue = value[1]
            }

            if (isMonthRange) {
                month = (dateValue as MonthRange).month + 1
                year = (dateValue as MonthRange).year
                if (!day) {
                    day = (new Date(year, month, 0)).getDate()
                }
            } else {
                month = (dateValue as Date).getMonth() + 1
                day = (dateValue as Date).getDate()
                year = (dateValue as Date).getFullYear()
            }

            return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
        }

        return null
    }

    public static getDayOfWeek (date: string|Date, addTimeToString = false): string {
        if (date === null || date === undefined) {
            return ''
        }

        if (typeof date === 'string') {
            if (addTimeToString) {
                date = date.concat(' 00:00')
            }
            date = new Date(date)
        }

        return date.toString().split(' ')[0]
    }

    public static dateOnly (date: string|Date|undefined|null): string {
        if (date === null || date === undefined) {
            return ''
        }

        if (typeof date === 'object') {
            date = date.toISOString()
        }

        if (date.indexOf('T') !== -1) {
            return date.split('T')[0]
        } else {
            return date.split(' ')[0]
        }
    }

    public static formatDate(value: Date|string|undefined|null, dayAdd = 0, format?: string): string {
        try {
            if (value === null || value === undefined) {
                return ''
            }
            const date = typeof value === 'string' ? parseISO(value) : value
            if (date) {
                if (dayAdd) {
                    date.setDate(date.getDate() + dayAdd)
                }
                return dateFormat(date, format || 'yyyy-MM-dd')
            }
            return ''
        } catch (err) {
            return value?.toString() || ''
        }
    }

    public static getMonths(): any {
        return {
            0: 'January',
            1: 'February',
            2: 'March',
            3: 'April',
            4: 'May',
            5: 'June',
            6: 'July',
            7: 'August',
            8: 'September',
            9: 'October',
            10: 'November',
            11: 'December',
        }
    }

    public static getSelectOptionsFromEnumKeys(type: Record<string, any>, includeBlankString = false): Option[] {
        const typeKeys = Object.keys(type) || []
        const options: Option[] = []

        if (includeBlankString) {
            options.push({ text: '', value: '' })
        }

        typeKeys.forEach((key: any) => {
            options.push({
                text: _.startCase(key),
                value: type[key as keyof typeof type],
            })
        })

        return options
    }

    public static getSelectOptionsFromEnumValues(type: Record<string, any>, includeBlankString = false): Option[] {
        const typeValues = Object.values(type) || []
        const options: Option[] = []

        if (includeBlankString) {
            options.push({ text: '', value: '' })
        }

        typeValues.forEach((value: any) => {
          if (value !== 'bi_weekly') { // todo add back in once we use bi_weekly
              options.push({
                  text: _.startCase(value),
                  value: value,
              })
          }
        })

        return options
    }

    public static getSelectOptions(rows: any[], textFieldsWithConcats: string[], valueField: string, emptyValueText = '', emptyValueValue: string|null = '', onlyRecordsWithValueIn = ''): Option[] {
        const options: Option[] = []

        if (emptyValueText) {
            options.push({
                text: emptyValueText,
                value: emptyValueValue,
            })
        }

        for (const row of rows) {
            let text = ''

            let index = 0

            for (const textFieldsWithConcat of textFieldsWithConcats) {
                text = index++ % 2 === 0 ? text.concat(row[textFieldsWithConcat]) : text.concat(textFieldsWithConcat)
            }
            if (!onlyRecordsWithValueIn || row[onlyRecordsWithValueIn]) {
                options.push({
                    text: text ? text : row,
                    value: valueField ? row[valueField] : row,
                })
            }
        }

        return options
    }

    public static getContractTypeText(contractType: string) {
        let contractTypeText = ''

        Object.values(ContractType).forEach(v => {
            if (v === contractType) {
                contractTypeText = _.startCase(contractType)
            }
        })

        return contractTypeText
    }

    public static async sleep (ms: number) {
        return new Promise(function (resolve) {
            setTimeout(resolve, ms)
        })
    }

    public static fixUploadData(data: any) {
        let o = "", l = 0
        const w = 10240
        for (; l < data.byteLength / w; ++l) {
            o += String.fromCharCode.apply(null, Array.from(new Uint8Array(data.slice(l * w, l * w + w))))
        }
        o += String.fromCharCode.apply(null, Array.from(new Uint8Array(data.slice(l * w))))

        return o
    }

    public static generateRandomColor() {
        return '#' + Math.floor(Math.random()*16777215).toString(16)
    }

    public static getLettersAndAnchors(rows: any[], letterKey: string): { letters: string[]; anchors: string[]} {
        const letters: string[] = []
        const anchors: string[] = []

        if (rows.length > 0) {
            let letter = rows[0][letterKey].substring(0, 1)
            letters.push(letter)
            let i = 1

            for (const row of rows) {
                const startLetter = row[letterKey].substring(0, 1)
                if (letter !== startLetter) {
                    letter = startLetter
                    letters.push(letter)
                    i = 1
                }
                anchors.push(`${letter}${i++}`)
            }
        }

        return { letters, anchors }
    }

    public static getHoursFromSeconds(seconds: number): number {
        return Math.round(((seconds / 60 / 60) + Number.EPSILON) * 100) / 100
    }

    public static getSecondsFromHours(hours: number): number {
        return hours * 60 * 60
    }

    public static getSecondsFromMinutes(minutes: number): number {
        return minutes * 60
    }

    public static getValidNumber(value: string|number|null, defaultValue: number, type: 'int'|'decimal'): number {
        let newValue = defaultValue

        if (value && typeof value === 'string') {
            if (type === 'int') {
                newValue = parseInt(value)
            } else {
                newValue = parseFloat(value)
            }
        }

        if (!value || isNaN(newValue) || value < defaultValue) {
            return defaultValue
        }

        return newValue
    }

    public static async getJiraBaseUrl(): Promise<string> {
        const response = await SettingService.getSetting('jiraBaseUrl')
        if (response) {
            return response.value as string
        }

        return ''
    }

    public static getPayPeriods(): TimeEntryPayPeriod[] {
        const currentDate = new Date()

        const payPeriods: TimeEntryPayPeriod[] = []

        const startDate = new Date()
        startDate.setMonth(startDate.getMonth() - 12)
        const endDate = new Date()
        endDate.setMonth(endDate.getMonth() + 12)

        do {
            let periodStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
            let periodEndDate = new Date(startDate.getFullYear(), startDate.getMonth(), 15, 23, 59, 59)
            let type: 'previous'|'current'|'future' = currentDate > periodEndDate ? 'previous' : currentDate < periodStartDate ? 'future' : 'current'

            payPeriods.push({
                type: type,
                startDate: periodStartDate,
                endDate: periodEndDate,
                rangeString: `${_.startCase(type)} (${dateFormat(periodStartDate, 'EEE M/d/yyyy')} - ${dateFormat(periodEndDate, 'EEE M/d/yyyy')})`,
            })

            periodStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), 16)
            periodEndDate = endOfMonth(startDate)
            type = currentDate > periodEndDate ? 'previous' : currentDate < periodStartDate ? 'future' : 'current'

            payPeriods.push({
                type: type,
                startDate: periodStartDate,
                endDate: periodEndDate,
                rangeString: `${_.startCase(type)} (${dateFormat(periodStartDate, 'EEE M/d/yyyy')} - ${dateFormat(periodEndDate, 'EEE M/d/yyyy')})`,
            })

            startDate.setMonth(startDate.getMonth() + 1)
        } while(startDate <= endDate)

        return payPeriods
    }

    public static generateTempPassword(length = 10, tempPasswordRules: TempPasswordRules|null = null) {
        const numbers = '0123456789'
        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz'
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXY'
        const specialCharacters = '!@#$%^&*()_'

        let tempPasswordArray = Array(length)

        let characterChoices = lowerCaseLetters
        tempPasswordArray[0] = lowerCaseLetters

        let counter = 1

        if (tempPasswordRules?.requiresSpecialCharacters) {
            characterChoices += specialCharacters
            tempPasswordArray[counter++] = specialCharacters
        }

        if (tempPasswordRules?.requiresNumber) {
            characterChoices += numbers
            tempPasswordArray[counter++] = numbers
        }

        if (tempPasswordRules?.requiresUpperAndLower) {
            characterChoices += upperCaseLetters
            tempPasswordArray[counter++] = upperCaseLetters
        }

        tempPasswordArray = tempPasswordArray.fill(characterChoices, counter)

        return this.shuffleArray(tempPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('')
    }

    public static shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            const temp = array[i]
            array[i] = array[j]
            array[j] = temp
        }

        return array
    }

    public static isValidEmail(email: string): boolean {
        const reg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return reg.test(email)
    }

    public static getDefaultTask(): EditingTask {
        return {
            id: -1,
            projectId: -1,
            epicServiceItemId: -1,
            assigneeUserId: -1,
            summary: '',
            issueEstimateSeconds: 0,
            beginDate: null,
            frequency: TaskIssueInformationFrequency.Weekly,
            lastCreatedDate: new Date(),
            labels: [],
            daysOfWeek: [],
            occurrence: '',
            collaborators: [],
        }
    }

    public static runAction(isStarting = true, message = '', messageType: 'success'|'error'|'info' = 'info', timeout = 3500) {
        message = message ? message : isStarting ? 'Import started' : 'Import complete'

        toast[messageType](message, { timeout: timeout})

        if (isStarting) {
            this.showLoading()
        } else {
            this.hideLoading()
        }
    }
}
